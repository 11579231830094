.dasboardCard {
    padding: 30px 0px;
    /* border: 2px dashed #1C407B; */
    border: 2px dashed #1c407b9e;
    box-shadow: 0px 0px 20px #6D6D6D1A;
    opacity: 1;
    border-radius: 12px !important;
}

.dashboardCard-item {
    display: flex;
    gap: 20px;
    align-items: center;
    width: 25%;
    justify-content: center;
    cursor: pointer;
}

.dashboardCard-title {
    color: #001323;
    font-size: 20px !important;
    font-weight: 500 !important;
    margin: 0 !important;
}

.dashboardCard-number {
    text-align: left;
    color: #001323;
    /* font-size: 40px !important; */
    font-size: 30px !important;
    font-weight: 600 !important;
}

.dashboardItem {
    display: flex;
    justify-content: center;
}


/* ======================== */

.dashboardCard-plant {
    background: transparent linear-gradient(180deg, #C9CFE7 0%, #E6E9F4 24%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 12px 20px #AAAAAA29;
    border: 1px solid #E6EAF2;
    border-radius: 8px !important;
    /* padding: 22px 18px; */
    /* padding: 10px 18px; */
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    transition: 0.5s !important;
}

.dashboardCard-plant:hover {
    box-shadow: 0px 12px 20px #AAAAAAc4 !important;
    transform: translateY(-3px) !important;
}

.dashboardCard-plantTitle {
    color: #001323;
    font-size: 24px !important;
    font-weight: 500 !important;
}

.dashboardCard-plantsubTitle {
    color: #001323;
    margin-bottom: 20px !important;
}

.dashboardCard-plantContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboardCard-plantText {
    color: #001323;
    /* font-size: 20px !important; */
    font-size: 13px !important;
    font-weight: 500 !important;
    margin: 0;
}

.dashboardCard-plantNumber1 {
    color: #50C878;
    /* font-size: 30px !important; */
    font-size: 20px !important;
    font-weight: 500 !important;
}

.dashboardCard-plantNumber2 {
    color: #E31E24;
    /* font-size: 30px !important; */
    font-size: 20px !important;
    font-weight: 500 !important;
    /* border-right: 1px solid black; */
}

.dashboardCard-plantSmalltext {
    color: #5473A5;
}

.dashboardCard-plantImg {
    /* width: 36px; */
    width: 24px;
    object-fit: cover;
    margin: 0 auto;
}

.dashboardCard-plantImg.alertImg {
    /* width: 30px; */
    width: 20px;
}

.dashboardCard-plantSmalltext {
    font-size: 8px !important;
}


/* @media(min-width:1200px) and (max-width:1500px) {
    .dashboardCard-plantContent {
        gap: 12px !important;
        justify-content: inherit !important;
    }
    .dashboardCard-plantImg {
        width: 26px;
    }
    .dashboardCard-plantText {
        font-size: 12px !important;
    }
    .dashboardCard-plantNumber1 {
        font-size: 16px !important;
    }
    .dashboardCard-plantNumber2 {
        font-size: 16px !important;
    }
    .dashboardCard-plantSmalltext {
        font-size: 10px !important;
    }
} */

@media(min-width:1199.98px) and(max-width:1400px) {
    .dashboardCard-plant {
        padding: 0px 0px !important;
    }
    .dashboardCard-plantText {
        font-size: 13px !important;
    }
    .dashboardCard-plantImg {
        width: 24px !important;
    }
    .dashboardCard-plantImg.alertImg {
        width: 20px !important;
    }
    .dashboardCard-plantNumber1 {
        font-size: 20px !important;
    }
    .dashboardCard-plantNumber2 {
        font-size: 20px !important;
    }
    .dashboardCard-plantSmalltext {
        font-size: 8px !important;
    }
    .dashboardCard-number {
        font-size: 30px !important;
    }
}

@media(min-width:899.98px) and (max-width:1024px) {
    .dashboardCard-img {
        width: 40px;
    }
    .dashboardCard-plant {
        padding: 14px 0px 14px 8px !important;
    }
    .dashboardCard-title {
        font-size: 18px !important;
    }
    .dashboardCard-number {
        font-size: 25px !important;
        text-align: center;
    }
    .dashboardCard-item {
        gap: 14px;
    }
    /* ======================= */
    .dashboardCard-plantContent {
        /* width: 150%; */
        gap: 12px !important;
        justify-content: inherit !important;
    }
    .dashboardCard-plantImg {
        /* width: 26px; */
        width: 20px;
    }
    .dashboardCard-plantImg.alertImg {
        width: 26px;
    }
    .dashboardCard-plantText {
        font-size: 12px !important;
    }
    .dashboardCard-plantNumber1 {
        font-size: 16px !important;
    }
    .dashboardCard-plantNumber2 {
        font-size: 16px !important;
    }
    .dashboardCard-plantSmalltext {
        font-size: 10px !important;
    }
}

@media (min-width: 767px) and (max-width: 899.98px) {
    .dashboardCard-img {
        width: 42px;
    }
    .dashboardCard-title {
        font-size: 14px !important;
    }
    .dashboardCard-number {
        font-size: 20px !important;
        text-align: center;
    }
    .dashboardCard-item {
        gap: 14px;
    }
     /* ============================================= */
     .dashboardCard-plantSmalltext{
        font-size: 9px !important;
    }
}