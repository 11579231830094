body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F1F1FF;
    /* position: relative; */
    z-index: -1;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
    /* padding: 10px 70px 10px 70px !important; */
    /* padding-right: 0 !important;
    padding-left: 0 !important;
    width: 100% !important; */
}

.container {
    /* padding-right: 15px !important;
    padding-left: 15px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    margin: 0 !important; */
    /* max-width: 2000px; */
    /* padding: 20px 20px 20px 0px; */
}


/* @media (min-width: 1600px) {
    .container {
        max-width: 1600px;
    }
}

@media (min-width: 1900px) {
    .container {
        max-width: 1900px;
    }
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
} */
.page-wraper {
    padding: 22px 24px 50px 33px !important;
  }
  .date-picker{
    width: 168px;
  }
  table.transparent-table,
table.transparent-table tr,
table.transparent-table td,
table.transparent-table th {
  background-color: transparent !important;
}
table.no-border-table,
table.no-border-table tr,
table.no-border-table td,
table.no-border-table th {
  border: 0px !important;
}
.form-input-production {
    margin-bottom: 11px !important;
    background-color: #f1f1ff;
    padding: 15px;
    border: none;
    border-radius: 4px;
    opacity: 1;
    width: 90% !important;
  }
  .css-gqssyb {
    min-width: 0 !important;
}
.react-datepicker__header {
    text-align: center;
    background: #1c407b !important;
    color: white !important;
    /* background-color: #f0f0f0; */
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
  }

  .react-datepicker__month-text--keyboard-selected {
    background: #1c407b !important;
    color: white !important;
  }
  .react-datepicker__navigation {
    top: 8px !important;
  }
  
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    top: 0;
    margin-top: -6px;
  }
  
  .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 5px !important;
  }
  .popup-box{
    background: white;
    height: 237px;
    width: 23%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.hikemm-btn{
  background: #1C407B;
border: 1px solid #1E3760;
border-radius: 4px;
opacity: 1;
padding: 10px 20px;
color: white;
}
.vms-btn{
  background: #1C407B;
border: 1px solid #1E3760;
border-radius: 4px;
opacity: 1;
padding: 10px 33px;
color: white;
}
  