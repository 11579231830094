.deviceModal-popup {
  width: 1024px;
  height: 670px;
  background-color: white;
  position: fixed;
  top: 17%;
  left: 25%;
  transform: translate(-50px, -50px);
  z-index: 99;
  box-shadow: 0px 0px 30px #0000008a;
  border-radius: 6px;
  padding: 20px;
}

.devicemodal-box {
  height: 495px;
  background-color: #f1f1ff;
  padding: 20px;
}

.devices-text h6 {
  font-size: 15px;
  color: #001323;
  font-weight: 700;
}

.devices-text {
  justify-content: space-between;
}

.selectionText {
  background-color: #ddddee;
  color: #e31e24;
  font-size: 14px;
  padding: 6px;
  border-radius: 2px;
}

.Device-caption {
  background-color: #fff;
  padding: 8px 13px;
  font-size: 15px;
}

.Device-caption1 {
  background-color: #1c407bc2;
  color: #fff;
  padding: 8px 13px;
  font-size: 15px;
}

.Device-caption p {
  margin-bottom: 0 !important;
  font-size: 13px;
}

.Device-caption h6 {
  margin-bottom: 3px !important;
  font-size: 15px;
  letter-spacing: 0.48px;
}

.device-table td {
  padding: 10px;
  font-size: 12px;
  letter-spacing: 0;
  padding-left: 5px !important;
  background-color: #f5f5f5;
}

.device-table th {
  padding: 10px;

}

.device-table {
  border-collapse: separate !important;
}

.device-table thead {
  position: sticky;
  top: 0;
  z-index: 999;
}

.selectTagDesc h6 {
  font-size: 15px;
  font-weight: 600;
}

.selectTagDesc p {
  font-size: 13px;
  margin-left: 8px;
}

.canclebtn {
  background: #ffffff;
  border: 1px solid #1e3760;
  border-radius: 4px;
  padding: 10px 50px;
  margin-right: 10px;
}

.submit-btn-disable {
  opacity: 0.5;
  pointer-events: none;
  background-color: #1e3760;
  color: #ffffff;
  border-radius: 4px;
  padding: 15px;
  border: 1px solid #1e3760;
  padding: 10px 50px;
}

.submitbtn {
  background-color: #1e3760;
  color: #ffffff;
  border-radius: 4px;
  padding: 15px;
  border: 1px solid #1e3760;
  padding: 10px 50px;
}

.activeDeviceimges {
  position: absolute;
  z-index: 9999;
  right: 60%;
  /* right: -8px; */
  top: 82px;
  height: 75px;
}

@media(min-width: 1025px) and (max-width: 1199.98px) {
  .deviceModal-popup {
    max-height: 550px !important;
    overflow: auto !important;
    left: 6% !important;
  }
}

@media(min-width: 1200px) and (max-width: 1536px) {
  .deviceModal-popup {
    max-height: 550px !important;
    overflow: auto !important;
    left: 16% !important;
  }
}


@media(min-width: 768px) and (max-width: 1024px) {
  .deviceModal-popup {
    max-height: 700px !important;
    overflow: auto !important;
    left: 14% !important;
  }

  .deviceModal-popup {
    width: auto !important;
    height: 670px;
    background-color: white;
    position: fixed;
    top: 19%;
    left: 25%;
    transform: translate(-50px, -50px);
    z-index: 99;
    box-shadow: 0px 0px 30px #0000008a;
    border-radius: 6px;
    padding: 20px;
  }

  .device-tag-list {
    margin-left: 140px;
  }
}