.userDetailsWrap {
    display: flex;
    align-items: center;
}

.userDetails.name {
    color: #273444;
    margin-bottom: 0px;
}

.alarmManagement.userCardModal {
    height: auto;
    width: 30%;
    /* padding: 12px 20px; */
    /* position: absolute; */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 26px #888888 !important;
    border-radius: 6px !important;
    z-index: 99;
}

.alarmMangemnt.userCardInnerWrap {
    padding: 16px 10px 10px 10px;
}

.alarmManagement.settingIcon {
    position: absolute;
    right: 20px;
    width: 28px;
}

/* .alaramManagement.groupDetails{
    display: flex;
    justify-content: space-between;
} */
.alarmManagement.groupDetails-wrap {
    padding: 10px 16px 10px 16px;
}

.groupDetails-innerWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.alarmManagement.groupDetailsBtnWrap {
    display: flex;
    gap: 10px;
    align-items: center;
}

.alarmManagementConfiguration-btn {
    border: 1px solid #103168;
    background-color: #fff;
    color: #001323;
    font-size: 14px;
    border-radius: 4px;
    padding: 4px 12px 4px 12px;
    /* width: 25%; */
    /* width: 18%; */
    height: 10%;
}
.alarmManagement.groupIcon{
    height: 20px;
}
.alarmManagement-DeviceList{
    display: flex;
    align-items: center;
    gap: 10px;
}
.alaramManagement-devicelist-BtnWrap{
    display: flex;
    gap: 10px;
}
.alarmManagement-deviceList-button{
    border: 0;
    border-radius: 2px;
    background-color: #DDDDEE;
    font-size: 14px;
}
.alarmMnagement-userDetailsWrap{
    display: flex;
    align-items: center;
    gap: 10px;
    padding-top: 10px;
}

@media (min-width: 899px) and (max-width: 1024px) {
    .alarmManagement.userCardModal {
        width: 40%;
        top: 50%;
    }
}

@media (min-width: 767px) and (max-width: 899.98px) {
    .alarmManagement.userCardModal {
        width: 40%;
        top: 50%;
    }
}