.production-data {
  padding: 16px;
  border-radius: 8px;
  background: white;
  /* width: 60%; */
}

.production-data .single-data-item,
.matching-data-section .single-data-item {
  border-right: 1px solid #a9b0bd;
}

.production-data .single-data-item,
.matching-data-section .single-data-item {
  border-right: 1px solid #a9b0bd;
}

.production-data .single-data-item:last-child,
.matching-data-section .single-data-item:last-child {
  border-right: 0px;
}

.production-data .common-table,
.matching-data-section .common-table {
  width: 100%;
  margin-left: 10px;
}

.production-data .common-table td,
.matching-data-section .common-table td:first-child {
  padding: 8px !important;
  font-size: 18px;
}

.production-data .common-table td:nth-child(2),
.matching-data-section .common-table td:nth-child(2) {
  padding: 8px !important;
}

.production-data-table .common-table td,
.matching-data-section .common-table td:first-child {
  padding: 8px !important;
  font-size: 18px;
}
.production-data-table {
  padding: 20px;
  border-radius: 8px;
  background: white;
  width: 30%;
}
.production-data-table1 {
  padding: 20px;
  border-radius: 8px;
  background: white;
  width: 90%;
}
.common-table td {
  padding: 0px;
}
.p-card h4 {
  font-size: 17px;
  font-weight: 600;
}
.pData {
  font-size: 16px;
  font-weight: 500;
}
.grid-data {
  background-color: red;
  height: 300px;
}
.chart-card {
  background-color: #ffff;
  height: 92%;
}

.production-btn {
  padding: 25px;
  font-size: 18px;
  width: 100%;
  color: #001323;
  font-weight: 500;
  background: #ffffff;
  border: 1px solid #103168;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  letter-spacing: 0.6px;
  font-weight: 700 !important;
}
.furnace-1 {
  max-height: 356px;
  overflow: auto;
}

.analytics-date-picker {
  height: 50px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #475467;
}
.consumerEnergyData-box {
  background-color: #fff;
  min-height: 681px;
  border-radius: 6px;
  padding: 10px;
}
.overlay {
  position: absolute;
  top: 23px;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5); /* transparent black */
  display: block; /* initially hidden */
  height: 100%;
}
.consumerData-boxes {
  background-color: #fff;
  min-height: 681px;
  border-radius: 6px;
  padding: 10px;
}
a:hover {
  color: #001323;
}

.page-header-analytics {
  display: flex;
  justify-content: space-between;
}
.prod-table {
  background-color: #aebcd2b3 !important;
  border: 2px dashed #1c407b;
  opacity: 0.8;
  border-right: 2px dashed #1c407b !important;
}
.production-tablecell {
  background-color: #aebcd2b3 !important;
}
.Analytics-deviceModal-popup {
  width: 472px;
  height: 636px;
  background: #ffffff;
  box-shadow: 0px 12px 30px #6e6e6e75;
  border-radius: 6px;
  position: fixed;
  top: 17%;
  left: 38%;
}
.chart-card-opacity {
  background: #aebcd2b3 !important;
  opacity: 0.8;
  border: 2px dashed #1c407b;
}
.form-input-production-pairs {
  margin-bottom: 11px !important;
  background-color: #f1f1ff;
  padding: 15px;
  border: none;
  border-radius: 4px;
  opacity: 1;
  width: 90% !important;
}
.form-input-production-pairs-hours {
  margin-bottom: 11px !important;
  background-color: #f1f1ff;
  padding: 15px;
  border: none;
  border-radius: 4px;
  opacity: 1;
}

.form-input-production-footprint {
  margin-bottom: 11px !important;
  background-color: #f1f1ff;
  padding: 15px;
  border: none;
  border-radius: 4px;
  opacity: 1;
  width: 90% !important;
}
.input-label {
  position: absolute;
  right: 5%;
  top: 16px;
  font-weight: 500;
}
.input-label-kt {
  position: absolute;
  right: 14%;
  top: 16px;
  font-weight: 500;
}
.input-label-rupees {
  position: absolute;
  right: 15%;
  top: 16px;
  font-weight: 500;
}
.input-label-ktt {
  position: absolute;
  right: 14%;
  top: 16px;
  font-weight: 500;
}
.editData {
  position: fixed;
  top: 34%;
  left: 37%;
  transform: translate(-50px, -50px);
  z-index: 99;
  box-shadow: 0px 0px 30px #0000008a;
  border-radius: 6px;
}
.editCard {
  width: 562px;
  height: 360px;
  padding: 25px 20px;
}
.slotBox {
  min-height: 203px;
  overflow: auto;
  background: #f1f1ff;
}
.analytics-cancle-btn {
  background: #ffffff;
  border: 1px solid #1e3760;
  border-radius: 4px;
  padding: 11px 55px;
  margin-right: 10px;
}
.analytics-submit-btn {
  background: #1c407b;
  border: 1px solid #1e3760;
  border-radius: 4px;
  padding: 11px 55px;
  color: #ffff;
}
.analytics-btns {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.addbtn {
  background: #ffffff;
  border: 1px solid #1e3760;
  border-radius: 4px;
  padding: 4px 17px;
  margin-top: -5px;
}
div.consumer-text:nth-child(1) {
  border-left: 4px solid #9a64b2;
}
div.consumer-text:nth-child(2) {
  border-left: 4px solid #ef75a0;
}
div.consumer-text:nth-child(3) {
  border-left: 4px solid #ef75a0;
}
div.consumer-text:nth-child(4) {
  border-left: 4px solid #ef75a0;
}
div.consumer-text:nth-child(5) {
  border-left: 4px solid #ef75a0;
}
div.consumer-text:nth-child(6) {
  border-left: 4px solid #ef75a0;
}
div.consumer-text:nth-child(7) {
  border-left: 4px solid #ef75a0;
}
div.consumer-text:nth-child(8) {
  border-left: 4px solid #ef75a0;
}
div.consumer-text:nth-child(9) {
  border-left: 4px solid #ef75a0;
}
.header-btn-date {
  margin-right: 13px;
  width: 31%;
}
.header-btn-month {
  width: 31%;
}
.addbtn-disable {
  background: #ffffff;
  border: 1px solid #1e3760;
  border-radius: 4px;
  opacity: 0.5;
}
.grid-container {
  flex-wrap: initial !important;
  gap: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .page-header-analytics {
    display: block !important;
  }
  .analysis-tabs-btn {
    display: block !important;
  }
  .editCard {
    width: 638px !important;
}
.editData{
  left: 16%;
}
  /* .analytics-datepicker {
    margin-top: 20px;
    margin-left: 480px;
  } */
  .header-btn-month {
    width: 22% !important;
  }
  .header-btn-date {
    width: 22% !important;
  }
  .production-data-table1 {
    width: 100%;
  }
  .dispatch-data {
    gap: 20px;
    flex-wrap: wrap;
  }
  .form-input-production-pairs {
    width: 88%;
  }
  .form-input-production {
    width: calc(100% - 20px) !important;
  }
  .form-input-production-footprint {
    width: calc(100% - 20px) !important;
  }
  .pData2 {
    margin-left: -23px !important;
  }
  .pData1 {
    margin-left: -26px !important;
  }
  .pData0 {
    margin-left: -13px !important;
  }
  .grid-container {
    flex-wrap: wrap !important;
    gap: 0px !important;
  }
  /* .input-label-rupees {
    left: 77%;
  }
  .input-label-ktt {
    left: 72%;
  } */
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .production-data {
    padding: 40px;
    border-radius: 8px;
    background: white;
    width: 100%;
  }

  .production-data .single-data-item,
  .matching-data-section .single-data-item {
    border-right: 0;
  }

  .production-data .single-data-item,
  .matching-data-section .single-data-item {
    border-right: 0;
  }

  .production-data-table {
    padding: 40px;
    border-radius: 8px;
    background: white;
    width: 100%;
  }
}

@media (max-width: 1199.98px) {
  .production-data-table {
    padding: 40px;
    border-radius: 8px;
    background: white;
    width: 100%;
  }
  .dispatch-data {
    gap: 20px;
    flex-wrap: wrap;
  }
  .production-data-table1 {
    width: 100%;
  }
  .grid-container {
    flex-wrap: wrap !important;
    gap: 0px !important;
  }

}
@media(min-width:1025px) and (max-width:1200px){
  .editCard{
    width: 650px;
  }
  .editData{
    left: 27%;
  }
}
@media (min-width: 1199.98px) and (max-width: 1536px) {
  .form-input-production-pairs {
    width: calc(100% - 9%) !important;
  }
  .form-input-production-pairs-hours {
    width: 136px;
  }
  .form-input-production {
    width: 135px;
  }
  .form-input-production-footprint {
    width: calc(100% - 9%) !important;
  }
  .pData2 {
    margin-left: -8px !important;
  }
  .pData1 {
    margin-left: -20px !important;
  }
  .pData0 {
    margin-left: -18px !important;
  }

  .header-btn-date {
    margin-right: 13px;
    width: 37%;
  }   
  .header-btn-month {
    width: 37%;
  }
  .editCard {
    width: 638px !important;
}
.editData{
  left: 32% !important;
}
}