.addDevice-button.plant {
    padding: 10px 21px;
}

.selectCompany-dropDown {
    background-color: #F1F1FF;
    width: 90%;
}

/* .selectCompany-dropDown select:focus {
    outline: none;
    border-color: none !important;
    box-shadow: none !important;
}
.selectCompany-dropDown select {
    border: none;
    border-radius: 4px;
    font-size: 16px;
    padding: 10px;
  }
   */

.plantmanagementDepartment-fieldWrap {
    /* padding: 12px; */
    padding: 9px;
    /* height: 40px; */
    width: 90%;
    background-color: #F1F1FF;
    position: relative;
    cursor: pointer;
    /* color: #F1F1FF; */
}

.activeDeviceimg {
    position: absolute;
    right: -8px;
    top: 1px;
    /* height: 70px; */
}

/* ============================================ */
/* .plantmanagementDepartment-fieldWrap.singleField:before {
    content: "";
    position: absolute;
    top: 50%;
    right: -12px; 
    transform: translateY(-50%);
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid #1E3760; 
  } */
/* ============================================ */

.plantmanagementDepartment-card {
    background-color: #F1F1FF;
    min-height: 620px;
    padding: 10px 10px 10px 10px;
}

.plantmanagementDepartment-addDevicewrap {
    display: flex;
    justify-content: end;
    align-items: center !important;
    gap: 14px;
    padding: 0px 10px 10px 0px;
}

.plantmanagementDepartment-assignDeviceTextWrap {
    display: flex;
    gap: 10px;
    cursor: pointer;
}

.plantmanagemetDepartment-assignDeviceText {
    color: #001323;
    margin-bottom: 0px !important;
    font-weight: 500 !important;
}

.addDeviceImg {
    width: 20px;
}

.addDeviceDetailsCard {
    padding: 10px 16px 10px 10px;
}

.addDeviceDetailsCard.gap {
    margin-top: 10px;
}

.addDeviceCardDetailsWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.addDeviceDetailsCardetails-wrap {
    display: flex;
}

.addDeviceDetailsCardetails {
    display: flex;
    flex-direction: column;
}

.addDeviceDetailsCardetails-text {
    margin: 0;
}

.addDeviceConfi-btn {
    border: 1px solid #103168;
    background-color: #fff;
    color: #001323;
    font-size: 14px;
    border-radius: 4px;
    padding: 6px;
    /* width: 25%; */
    width: 18%;
    height: 10%;
}

.addPlant-cardModal {
    height: auto;
    width: 35%;
    /* padding: 12px 20px; */
    /* position: absolute; */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 26px #888888 !important;
    border-radius: 6px !important;
    z-index: 99;
}
.assignDeviceModalWrap:nth-child(odd) {
    background-color: #F1F1FF;
}
/* .assignDeviceModalWrap:nth-child(even) {
    background-color: #F1F1FF;
} */
.assignDevice-cardModal {
    /* height: auto; */
    /* height: 525px; */
    height: 500px;
    /* height: 300px; */
    /* min-height: 525px; */
    /* overflow: auto !important; */
    width: auto;
    /* padding: 12px 20px; */
    /* padding: 10px 0px 10px 0px; */
    /* position: absolute; */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 26px #888888 !important;
    z-index: 1;
}

.assignDevice-cardTitle {
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 10px 10px 34px 10px;
    z-index: 99;
}

.assignDevice-cardTitle1 {
    position: sticky !important;
    bottom: 0 !important;
    background-color: white;
    padding: 10px;
    z-index: 99;
}

.assignDeviceSearch {
    height: 10px !important;
}

.assignDeviceDetails {
    padding: 6px 0px 0px 0px;
}

.assignDeviceModalWrap {
    height: 54px;
    overflow-y: auto;
}

.assignDeviceDetails:nth-child(odd) {
    /* background-color: #F1F1FF; */
    /* width: 50%;
    height: 100px;
    overflow: auto; */
}

.assignDeviceDetailsWrap {
    display: flex;
    align-items: center;
    gap: 2px;
}

.assignDeviceDetailsTitle {
    font-weight: 600 !important;
    color: #001323;
    margin-bottom: 0px !important;
}

.assignDeviceDetailsDisc {
    font-weight: 500 !important;
    color: #001323;
    margin-bottom: 0px !important;
}

.assignDevice-submitBtnWrap {
    /* position: sticky; */
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: #fff;
    /* padding-top: 10px; */
    z-index: 99;
    padding: 10px 0px 20px 0px;
}

.assignDevice-submitButton {
    background-color: #1C407B;
    color: #fff;
    border: 1px solid #1E3760;
    border-radius: 3px;
    /* width: auto; */
    margin: 0 auto;
    display: flex;
    width: 36%;
    padding: 8px 32px;
    margin-top: 20px;
    /* margin-bottom: 10px; */
    text-align: center;
    text-transform: capitalize;
}

.addPlant-titleWrap {
    padding: 0;
    text-align: center !important;
    background-color: #1C407B;
    padding: 24px 0px 14px 0px;
}

.addPlant-title {
    color: #fff;
    font-weight: 500 !important;
}

.addplantMdal-innerWrap {
    padding: 0px 25px 25px 25px;
}

.addplantInputfield {
    width: 100%;
}

.addPlant-cardButtons {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.addPlantBtn {
    background-color: #1C407B;
    color: #fff;
    border: 1px solid #1E3760;
    border-radius: 4px;
    /* width: auto; */
    width: 100%;
    padding: 12px 82px;
    margin-top: 20px;
    /* letter-spacing: 1.8px; */
    text-transform: capitalize;
}

.Plantmanagemet.submit {
    background-color: #1C407B;
    color: #fff;
    border: 1px solid #1E3760;
    border-radius: 4px;
    padding: 8px 60px;
    margin-top: 20px;
    text-transform: capitalize;
    float: right;
}

@media(min-width:1024px) and (max-width:1199.98px) {
    .assignDevice-submitButton {
        padding: 8px 43px;
    }
}

@media (min-width: 899px) and (max-width: 1024px) {
    .assignDevice-submitBtnWrap {
        padding-top: 10px;
        padding-bottom: 22px;
    }

    .assignDevice-cardModal {
        height: 450px;
        padding: 0px;
    }

    .assignDevice-submitButton {
        padding: 8px 43px;
    }

    .addPlant-cardModal {
        width: 50%;
        top: 50%;
    }
}

@media (min-width: 767px) and (max-width: 899.98px) {
    .assignDevice-submitBtnWrap {
        padding-top: 10px;
        padding-bottom: 22px;
    }

    .assignDevice-cardModal {
        /* height: auto; */
        /* height: 525px; */
        /* height: 300px;
        min-height: 525px; */
        /* overflow: auto !important; */
        height: 450px;
        width: auto;
        padding: 0px;
        /* padding: 12px 20px; */
        /* padding: 10px 0px 10px 0px; */
        /* position: absolute; */
        /* position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0px 4px 26px #888888 !important;
        z-index: 1; */
    }

    .selectCompany-dropDown {
        width: 100%;
    }

    .plantmanagementDepartment-fieldWrap {
        width: 100%;
    }

    .addPlant-cardModal {
        width: 50%;
        top: 50%;
    }

    .assignDevice-submitButton {
        padding: 8px 43px;
    }
}

.container-1 {
    background-color: #fff;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    width: 500px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 26px #888888 !important;
    z-index: 1;
}

.container__header {
    background-color: #fff;
    /* background-color: green; */
    z-index: 99;
}

.container__header h6 {
    text-align: center;
    color: #1C407B;
    font-weight: 600;
    font-size: 19px;
}

.container__content {
    z-index: 0;
    /* background-color: yellow; */
    max-height: 265px;
    margin-top: 14px;
    overflow: auto;
}

.container__footer {
    width: 100px;
    margin: 0 auto;
    background-color: #1C407B;
    color: #fff;
    border: 1px solid #1E3760;
    border-radius: 3px;
    padding: 8px 24px;
}