    .titleWrap {
        display: flex;
        align-items: center;
    }
    .containerWrap{
        margin: 0px 0px 50px 0px;
    }
    
    .dasboardCard {
        /* padding: 17px 0px; */
        padding: 8px 0px 0px 0px;
        /* border: 1px dashed #1C407B; */
        /* border:  2px solid dashed #1c407b9e; */
        box-shadow: 0px 0px 20px #6D6D6D1A;
        opacity: 1;
        border-radius: 12px !important;
        position: relative;
    }
    
    .detailsImage {
        position: absolute;
        top: 10px;
        right: 8px;
    }
    
    .dashboardCard-item {
        display: flex;
        gap: 20px;
        align-items: center;
        width: 25%;
        justify-content: center;
        cursor: pointer;
    }
    
    .superAdmin-dashboardCard-title {
        color: #001323;
        /* font-size: 20px !important; */
        font-size: 16px !important;
        font-weight: 500 !important;
        margin: 0 !important;
        text-align: left !important;
    }
    
    .suparAdmin-dashboardCard-number {
        text-align: left;
        color: #001323;
        font-size: 40px !important;
        font-weight: 600 !important;
    }
    
    .dashboardItem {
        display: flex;
        justify-content: center;
    }
    /* ======================== */
    
    .superAdminDashboard-card {
        padding: 34px 60px 34px 60px;
        border-radius: 8px !important;
        position: relative;
        cursor: pointer;
        transition: 0.5s !important;
    }
    
    .superAdminDashboard-card:hover {
        box-shadow: 0px 12px 20px #AAAAAAc4 !important;
        transform: translateY(-3px) !important;
    }
    
    .superAdminDashboard-title {
        color: #001323;
        font-weight: 500 !important;
        /* font-size: 35px !important; */
        font-size: 25px !important;
    }
    
    .superAdmin-dashboardCard-image {
        height: 70px;
    }
    
    .alertImage {
        position: absolute;
        top: 8px;
        right: 42px;
    }
    
    .detailsIcon {
        position: absolute;
        top: 10px;
        right: 11px;
    }
    
    .superAdminDashboardcard-title {
        color: #001323;
        font-weight: 500 !important;
        /* font-size: 35px !important; */
    }
    
    .superAdmin-dashboardCard-item {
        display: flex;
        gap: 20px;
        align-items: center;
        width: 25%;
        justify-content: center;
        cursor: pointer;
    }
    
    .superAdmin {
        border: 0;
    }
    
    .superAdminDashboard-text {
        color: #001323;
        font-size: 20px !important;
        font-weight: 500 !important;
        margin: 0 !important;
    }
    
    .clientManagement-image {
        height: 60px !important;
    }
    
    @media(min-width:1534px) and (max-width:1825px) {
        .superAdminDashboard-card {
            /* padding: 42px 20px 20px 20px; */
            /* padding: 28px 20px 20px 20px; */
        }
    }
    /* @media(min-width:1200px) and (max-width:1600px) {
        .superAdminDashboard-card {
            padding: 34px 60px 34px 58px;
        }
        .superAdmin-dashboardCard-title {
            font-size: 20px !important;
        }
    } */
    
    @media(min-width:1025px) and (max-width:1640px) {
        /* .superAdminDashboard-card {
            padding: 34px 60px 34px 58px;
        } */
        /* .superAdmin-dashboardCard-img {
            width: 50px;
        } */
        .superAdmin-dashboardCard-title {
            /* font-size: 13px !important; */
            /* font-size: 16px !important; */
            /* font-size: 28px !important; */
        }
        /* .suparAdmin-dashboardCard-number {
            font-size: 34px;
            text-align: center;
        }
        .superAdmin-dashboardCard-item .item {
            flex-direction: column !important;
            gap: 10px;
        }
        .superAdminDashboardcard-title {
            font-size: 20px !important;
        }
        .superAdminDashboard-title {
            font-size: 28px !important;
        }
        .clientManagement-image {
            width: 50px;
        } */
    }
    
    @media(min-width:1199.98px) and (max-width:1400px) {
        .dasboardCard {
            padding: 8px 0px !important;
        }
        .superAdmin-dashboardCard-title {
            font-size: 14px !important;
        }
        .suparAdmin-dashboardCard-number {
            font-size: 36px !important;
        }
        .superAdminDashboard-card {
            /* padding: 34px 0px 34px 24px; */
            padding: 34px 0px 34px 14px;
        }
        .superAdmin-dashboardCard-image {
            height: 60px;
        }
        .clientManagement-image {
            height: 60px !important;
        }
        .superAdminDashboardcard-title {
            font-size: 22px !important;
        }
    }
    
    @media(min-width:899.98px) and (max-width:1024px) {
        .dashboardCard-item {
            flex-direction: column;
        }
        .suparAdmin-dashboardCard-number {
            text-align: center;
        }
        .superAdmin-dashboardCard-item .item {
            flex-direction: column !important;
            gap: 10px;
        }
        .superAdminDashboardcard-title {
            font-size: 20px !important;
        }
        .superAdminDashboard-title {
            font-size: 20px !important;
        }
        .clientManagement-image {
            width: 50px;
        }
    }
    
    @media (min-width: 767px) and (max-width: 899.98px) {
        .dashboardCard-item {
            flex-direction: column;
        }
        .suparAdmin-dashboardCard-number {
            text-align: center;
        }
        .superAdmin-dashboardCard-item .item {
            flex-direction: column !important;
            gap: 10px;
        }
        .superAdminDashboardcard-title {
            font-size: 20px !important;
        }
        .superAdminDashboard-title {
            font-size: 30px !important;
        }
        .clientManagement-image {
            width: 50px;
        }
    }