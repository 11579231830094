.accessManagement-checkbox {
    width: 20px;
}

.accessManagement-text {
    width: 10%;
    text-align: center !important;
}

.manageDepartment-button {
    background-color: #fff;
    color: #3C4856;
    border: 1px solid #475467;
    border-radius: 4px;
    padding: 0px 20px;
}