.ackbtn {
  background: #1c407b;
  border: 1px solid #1e3760;
  border-radius: 4px;
  opacity: 1;
  font-size: 16px;
  letter-spacing: 0.48px;
  color: #ffffff;
  padding: 15px 18px;
}
.priority-status {
  width: 20px;
  height: 20px;
  background: #e31e24;
  border-radius: 2px;
  opacity: 1;
}
.search-table-row-select{
padding: 0 !important;
padding-left: 20px !important;
padding-top: 9px !important;
}

.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus 
{
  background-color: none !important;
}
.priority-statusInprogress {
  width: 20px;
  height: 20px;
  background: #FFA500;
  border-radius: 2px;
  opacity: 1;
}
.priority-statusClose{
  width: 20px;
  height: 20px;
  background: #EBEB27;
  border-radius: 2px;
  opacity: 1;
}
.priority-status1{
  width: 20px;
  height: 20px;
 border:2px solid #1C407B;
  border-radius: 2px;
  opacity: 1;
}
.selectDropdown{
  padding: 0 !important;
}
.search-table-row .search-table-row1{
  padding: 0px !important;
}
/* .alarm-table th {
  position: sticky !important;
  top: 0;
} */
.alarmpopup {
  background: #ffffff;
  box-shadow: 0px 0px 30px #0000008a;
  border-radius: 6px;
  padding: 30px;
}
.alarm-table-group table thead th {

  background-color: #fff;
  border-bottom: 0px !important;
 
  padding: 0px !important;
}
.alarm-table thead {
    position: sticky;
    top: 0px;
    z-index: 999;
}
.alarm-table td {
  padding: 5px;
}
table.alarm-table {
    width: 100%;
    z-index: -1;
    margin: auto;
    overflow: auto;
    border-collapse: separate;
    border-spacing: 0;
}
.alarm-table tr:nth-child(odd) {
  background-color: #F4F7FE
}

.alarmData {
  position: fixed;
  top: 42%;
  left: 42%;
  transform: translate(-50px, -50px);
  z-index: 999;
  box-shadow: 0px 0px 30px #0000008a;
  border-radius: 6px;
}
.alarmCard {
  height: auto;
  width: auto;
  padding: 25px 10px;
}
.card-content {
  margin-top: 13px;
  color: #1c407b;
}
.alarmYes {
  background: #1c407b;
  border: 1px solid #1e3760;
  border-radius: 4px;
  padding: 9px 22px;
  letter-spacing: 0.48px;
  color: #ffffff;
  margin-right: 15px;
}
.alarmNo {
  background: #a9b0bd;
  border: 1px solid #a9b0bd;
  border-radius: 4px;
  padding: 9px 22px;
  letter-spacing: 0.48px;
  color: #001323;
}
.search-table-row {
  /* padding: 5px 16px 5px 40px !important; */
  background: #f1f1ff;
  height: 50px;
  display: flex;
  /* min-width: 400px; */
  max-width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 1024px){
.alarmData {
    position: fixed;
    top: 50%;
    left: 34%;
    transform: translate(-50px, -50px);
    z-index: 99;
    box-shadow: 0px 0px 30px #0000008a;
    border-radius: 6px;
}
}
@media only screen and (min-width: 1024px) and (max-width: 1536px){
    .alarmData {
        position: fixed;
        top: 55%;
        left: 42%;
        transform: translate(-50px, -50px);
        z-index: 99;
        box-shadow: 0px 0px 30px #0000008a;
        border-radius: 6px;
    }
    }