/* .text {
    display: flex;
    gap: 20px;
    align-items: center;
}

.wrap {
    display: flex;
    gap: 50px;
}

.image-Wrap {
    display: flex;
    gap: 20px;
}

@media (max-width: 767.98px) {
    .wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .image-Wrap {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
}

@media (min-width:280px) and (max-width: 576px) {
    .wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .image-Wrap {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
} */
.www {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ooo {
    padding-bottom: 0px !important;
}

.device-tab {
    background-color: #1c407b;
    border: 1px solid #103168;
    color: white;
    padding: 10px;
    width: 200px;
    font-weight: 500;
    font-size: 18px;
    border-radius: 2px 2px 2px 2px;
}

.user-tab {
    background-color: white;
    border: 1px solid #103168;
    color: #103168;
    padding: 10px;
    width: 200px;
    font-weight: 500;
    font-size: 18px;
    border-radius: 2px 2px 2px 2px;
}

.alarmMangement-deviceList-card {
    min-height: 750px;
    padding: 10px 12px 0px 12px;
    position: relative;
}

.alarmMangement-deviceList-title {
    margin-bottom: 20px;
}

.alarmManagement-deviceLlistWrap {
    background-color: #F1F1FF;
    padding: 8px 0px 8px 0px;
}

.alarmManagement-deviceListContent {
    display: flex;
    align-items: center;
    gap: 4px;
}

.alarmManagement-device-innerContent {
    display: flex;
    flex-direction: column;
}

.alarmManagement-devicList-nextButton {
    background-color: #1C407B;
    color: #fff;
    border: 1px solid #1E3760;
    border-radius: 4px;
    padding: 8px 60px;
    margin-top: 20px;
    text-transform: capitalize;
    position: absolute;
    right: 16px;
    bottom: 16px;
}

.alarmMangementCard-useListCard1 {
    background-color: #F1F1FF;
    min-height: 620px;
    margin-right: 10px;
}

.alarmMangementCard-useListCard {
    background-color: #F1F1FF;
    min-height: 620px;
}

.alarmMangement-UserList-fieldWrap {
    /* padding: 12px; */
    padding: 19px;
    /* height: 40px; */
    width: 90%;
    background-color: #F1F1FF;
    position: relative;
    cursor: pointer;
    /* color: #F1F1FF; */
}

.activeDeviceimg {
    position: absolute;
    right: -8px;
    top: 1px;
    /* height: 70px; */
}

.alarmManagementCard-Roleswrap {
    display: flex;
    align-items: center;
}

.alarmaManagementCard-UserDetailsWrap {
    display: flex;
    gap: 42px;
    /* align-items: center; */
    /* justify-content: space-between; */
    padding: 20px 10px 20px 10px;
}

.alarmaManagementCard-alarmPriorityWrap {
    display: flex;
    /* align-items: center; */
    gap: 9px;
}

.alarmaManagementCard-checkboxWrap {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.alarmManagementCard-UserDetail-ContentWrap {
    display: flex;
    align-items: center;
    gap: 80px;
}

.alarmManagementCard-UserDetail-innerWrap {
    display: flex;
    align-items: center;
}

.alarmManagementCard-UserDetail-Content-inner-Wrap {
    display: flex;
    align-items: center;
}
@media (min-width: 1024px) and (max-width: 1199.98px) {
    .alarmMangement-UserList-fieldWrap {
        width: 100%;
    }
    .alarmMangementCard-useListCard1 {
        margin-right: 0px;
    }
    .alarmaManagementCard-UserDetailsWrap{
        justify-content: space-between;
    }
}
@media (min-width: 899px) and (max-width: 1024px) {
    .alarmMangement-UserList-fieldWrap {
        width: 100%;
    }
    .alarmMangementCard-useListCard1 {
        margin-right: 0px;
    }
    .alarmaManagementCard-UserDetailsWrap{
        justify-content: space-between;
    }
}

@media (min-width: 767px) and (max-width: 899.98px) {
    .alarmMangement-deviceList-card {
        height: auto;
    }

    .alarmMangement-UserList-fieldWrap {
        width: 100%;
    }

    .alarmMangementCard-useListCard1 {
        margin-right: 0px;
    }
    .alarmaManagementCard-UserDetailsWrap{
        justify-content: space-between;
    }
}