.deviceManagementHeader-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
}

.deviceManagementTitle-wrap {
    display: flex;
    align-items: center;
    gap: 4px;
}

.deviceManagementHeader-wrap .backArrow {
    cursor: pointer;
}

.addDevice-buttonWrap {
    display: flex;
    gap: 10px;
}

.addDevice-button {
    background-color: #1C407B;
    color: #fff;
    /* margin-top: -22px;
    margin-bottom: 6px; */
    border: 1px solid #1E3760;
    border-radius: 4px;
    /* letter-spacing: 1.6px; */
    text-transform: capitalize;
    padding: 0px 20px;
}

.tableResponsive {
    width: 100%;
    overflow-x: auto;
    padding-top: 20px;
}

.deviceManagementTable  {
    background: #a9b0bd;
    border: 1px solid #a9b0bd;
    border-radius: 8px;
    overflow: hidden;
}

.deviceManagementTable,
tr,
td,
th {
    border: 1px solid #a9b0bd !important;
}

.deviceManagementTable tr {
    background-color: #fff;
}

.deviceManagementTable th {
    /* padding: 16px 20px !important; */
    line-height: 1 !important;
    /* font-size: 18px !important; */
    font-size: 16px;
}

/* td {
    padding: 0 20px !important;
    line-height: 1 !important;
    white-space: nowrap;
    color: red !important;
} */

.addDeviceModal-card {
    height: auto;
    width: auto;
    /* padding: 12px 20px; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 26px #888888 !important;
    border-radius: 6px !important;
    z-index: 99;
}

.addDeviceModal-headerWrap {
    padding: 18px 25px 6px 25px;
}

.addDeviceModal-titleWrap {
    padding: 0;
    text-align: center !important;
    background-color: #1C407B;
    padding: 24px 0px 14px 0px;
    position: relative;
}
.closeImg{
    position: absolute;
    right: 20px;
    top: 25px;
}

.addDeviceModal-title {
    color: #fff;
    font-weight: 500 !important;
}

.addDeviceModal-innerWrap {
    padding: 0px 25px 25px 25px;
}

.addDeviceModal-checkBoxWrap {
    display: flex;
    align-items: center;
    gap: 2px;
}

.addDeviceModal-text {
    margin-top: 4px !important;
}

.addDeviceBtn {
    background-color: #1C407B;
    color: #fff;
    border: 1px solid #1E3760;
    border-radius: 4px;
    /* width: auto; */
    width: 100%;
    padding: 12px 82px;
    margin-top: 20px;
    /* letter-spacing: 1.8px; */
    text-transform: capitalize;
}


/* ================================================================== */


/* .tableResponsive {
    width: 100%;
    overflow-x: auto;
} */

.deviceManagementTable-title th {
    color: #3C4856 !important;
    font-weight: 700;
}

.deviceManagementTable-data th {
    color: #3C4856 !important;
    font-weight: normal !important;
}

.deviceManagementTable-license {
    display: flex !important;
    gap: 10px !important;
}

.deviceManagementTable-icon {
    display: flex !important;
    gap: 12px !important;
}

.cloudActiveImg {
    cursor: pointer;
}

.deleteConfirmation-Modal {
    height: auto;
    width: auto;
    /* padding: 12px 20px; */
    /* position: absolute; */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 26px #888888 !important;
    border-radius: 6px !important;
    padding: 30px 80px 30px 80px;
    z-index: 99;
}

.deleteConfirmation-title {
    text-align: center;
    color: #1C407B;
    font-weight: 700 !important;
}

.deleteConfirmation-btnWrap {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.deleteConfirmation-Yesbtn {
    background-color: #1C407B;
    color: #fff;
    border: 1px solid #1E3760;
    border-radius: 4px;
    /* width: auto; */
    width: 50%;
    padding: 10px;
    margin-top: 20px;
    letter-spacing: 1.8px;
    text-transform: capitalize;
}

.deleteConfirmation-Nobtn {
    background-color: #A9B0BD;
    color: #001323;
    border: 1px solid #A9B0BD;
    border-radius: 4px;
    /* width: auto; */
    width: 50%;
    padding: 10px;
    margin-top: 20px;
    letter-spacing: 1.8px;
    text-transform: capitalize;
}


/* ====================================== */

.search-table-row {
    position: relative;
    padding: 5px 16px 5px 40px !important;
    background: #F1F1FF;
    height: 50px;
    border: 1px solid #A9B0BD;
    border-radius: 4px;
    display: flex;
    /* min-width: 400px;
    max-width: 100%; */
}

.search-icon {
    color: #90a3b9;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    height: 24px !important;
    width: 24px !important;
}

.search-table-row input {
    background: transparent;
    border: 0;
    min-width: 80px;
    width: 100%;
}

.search-table-row input:focus {
    outline: none;
}

.deviceManagementTable tr:hover {
    background-color: #fff !important;
}

.deviceManagementTable tr:nth-child(even) {
    background-color: #F1F1FF !important;
}

.MuiInput-underline:before {
    border-bottom: 0px !important
}

.MuiInput-underline:after {
    border-bottom: 0px !important
}

@media(max-width:1400px) {
    .addDeviceModal-card {
        /* border-radius: 6px!important;
        box-shadow: 0 4px 26px #888!important;
        height: auto;
        left: 50%;
        position: absolute; */
        top: 76%;
        /* -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: auto;
        z-index: 99; */
    }
}

@media(min-width:899.98px) and (max-width:1024px) {
    .addDeviceModal-card {
        width: auto;
        height: auto;
        top: 50%;
    }
    .addDeviceBtn {
        width: 100%;
        padding: 14px;
    }
}

@media (min-width: 767px) and (max-width: 899.98px) {
    .addDeviceModal-card {
        width: auto;
        height: auto;
        /* top: 50%; */
    }
    .addDeviceBtn {
        width: 100%;
        padding: 14px;
    }
}

@media (max-width: 767.98px) {
    .page-header .header-btn-group {
        width: 100%;
    }
    .search-table-row {
        order: 5;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .MuiInputBase-input {
        min-width: 35px !important;
    }
    .MuiInputBase-input {
        min-width: 35px !important;
    }
}

@media (max-width: 599.98px) {
    .MuiInputBase-input {
        min-width: 35px !important;
    }
}


/* @media (max-width: 1499.98px) {
    .MuiInputBase-input {
        min-width: 35px !important;
    }
    .search-table-row input {
        font-size: 14px;
    }
} */