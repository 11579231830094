.addDevice-button.managementDepartment {
  width: 180px !important;
}

.addDepartment-card {
  height: 282px;
  width: auto;
  padding: 12px 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 26px #888888 !important;
  /* position: relative !important; */
  z-index: 99;
}
.managementDepartment-button {
  background-color: #1c407b;
  color: #fff;
  margin-top: -22px;
  margin-bottom: 8px;
  border: 1px solid #1e3760;
  border-radius: 4px;
  /* letter-spacing: 1.6px; */
  text-transform: capitalize;
  padding: 0px 14px;
}
.addDepartmentTitle {
  text-align: center !important;
}

.abcd {
  position: absolute;
  top: 0px;
  right: 0px;
}

.departmentWrap {
  padding-top: 20px;
}

.departmentWrap #exampleInputEmail1::placeholder {
  color: #001323;
  font-size: 14px;
}

#exampleInputEmail1 {
  box-shadow: none;
  border: 1px solid #000000;
  padding: 10px;
}

.form-control:focus {
  border-color: #000000;
}

.departmentWrap-input {
  box-shadow: none;
}

.departmentWrap span {
  color: #e31e24;
}

.addDepartmentButton {
  background-color: #1c407b;
  color: #fff;
  border: 1px solid #1e3760;
  border-radius: 4px;
  width: 180px;
  /* padding: 10px 16px; */
  padding: 10px 30px;
  /* letter-spacing: 1.6px; */
  text-transform: capitalize;
  margin: 0 auto;
  display: flex;
  margin-top: 30px;
}

.manageDepartment-card {
  /* height: 750px; */
  min-height: 750px;
}

.manageDepartment-wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.manageDepartment-fieldWrap {
  /* padding: 12px; */
  padding: 9px;
  /* height: 40px; */
  /* width: 100%; */
  width: 90%;
  background-color: #f1f1ff;
  position: relative;
  cursor: pointer;
  /* color: #F1F1FF; */
}
.activeDepartmentimg {
  position: absolute;
  right: -4px;
  top: 0px;
  height: 42px;
}

.manageDepartment-fieldWrap::before {
  /* content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 29;
    height: 28;
    background-color: red;
    transform: translateY(-50%) rotate(45deg);
    z-index: 0; */
  /* content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 2px;
    background-color: #1C407B; */
}
.managementDepartment-card {
  background-color: #f1f1ff;
  min-height: 620px;
  padding: 10px 10px 10px 10px;
}
.managementDepartment-addRoleswrap {
  display: flex;
  justify-content: end;
  align-items: center !important;
  gap: 6px;
  padding: 0px 10px 10px 0px;
}
.manageDepartment-assignRolesText {
  color: #001323;
  margin-bottom: 0px !important;
  font-weight: 500 !important;
}

.manageDepartment-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manageDepartment-fieldIcon {
  display: flex;
  justify-content: end;
  align-items: center !important;
  gap: 12px;
}
.managementDepartment.roles {
  display: flex;
  justify-content: space-between;
}

/* ========================================== */

.manageDepartment-card.roles {
  background-color: #f1f1ff;
  height: 600px;
}
.managementDepartment.icon {
  display: flex;
  gap: 10px;
}
.manageDepartment.roleInputWrap {
  padding-top: 10px;
}
.manageDepartment.roleInput {
  display: flex;
  gap: 30px;
  align-items: center;
  /* justify-content: space-between; */
}

.manageDepartment-input {
  width: 90%;
  border-radius: 4px;
  padding: 6px;
  border: 1px solid #000;
}
.manageDepartment-input::placeholder {
  font-size: 12px;
}

.manageDepartment.addRoleButton {
  background-color: #1c407b;
  border: 1px solid #1e3760;
  color: #fff;
  border-radius: 4px;
  width: 100px;
  padding: 6px;
}

.mangementDepartment label {
  visibility: hidden;
}

/* ========================================== */

@media (min-width: 899.98px) and (max-width: 1024px) {
  /* .manageDepartment-fieldWrap {
        width: 100%;
    } */
  .manageDepartment-card.roles {
    background-color: #f1f1ff;
    height: auto;
  }
}

@media (min-width: 767px) and (max-width: 899.98px) {
  /* .userpofileCard {
        height: auto;
    } */
  .manageDepartment-fieldWrap {
    width: 100%;
  }
  .manageDepartment-card.roles {
    background-color: #f1f1ff;
    height: auto;
  }
}
