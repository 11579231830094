.processDashboar-meterCard {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 2px;
}

.processDashboard-chartText {
    text-transform: uppercase;
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
    color: #001323;
    letter-spacing: 0px;
    font-weight: 600 !important;
}

.device-desc {
    font-size: 16px;
    color: #001323;
    letter-spacing: 0px;
    font-weight: 600 !important;
}

.device-number {
    font-size: 30px !important;
    letter-spacing: 0px;
    color: #001323;
    font-weight: 700 !important;
}

.process-card {
    display: flex;
    gap: 10px;
}

.unit {
    text-align: end;
    color: rgb(0, 19, 35);
    /* margin-top: -6px !important; */
    letter-spacing: 0.42px;
    font-size: 15px !important;
}

.process-card-chart {
    display: flex;
    gap: 15px;
}

.overViewButton {
    background: #ffffff;
    box-shadow: 0px 6px 12px #67676747;
    border: 1px solid #e31e24;
    border-radius: 4px;
}

.Process-dashboard-btn {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 12px #67676747;
    border: 1px solid #e31e24;
    border-radius: 4px;
    width: 100%;
    padding: 20px;
    color: #001323 !important;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    font-weight: 700;
}

.active-process-dashboard {
    background: #e31e24 0% 0% no-repeat padding-box;
    border: 1px solid #e31e24;
    border-radius: 4px;
    width: 100%;
    padding: 20px;
    color: #ffffff !important;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    font-weight: 700;
}

.graph-box {
    display: flex;
    /* margin-bottom: 40px; */
}

.Process-dashboard-btn:hover {
    text-decoration: none;
}

.process-header-btn-date {
    width: 35%;
    margin-right: 10px;
}

.process-header-btn-month {
    width: 35%;
}

.graph-date {
    display: flex;
}

.barChart-background {
    padding-top: 10px;
}

.slider-title {
    margin-left: 10px;
    /* min-height: 100px; */
    /* padding-left: 30px; */
    /* border-right: 4px solid  #5A8A8A; */
    padding-left: 12px;
    /* border-left: 4px solid #5A8A8A; */
}

.sliderColor1 {
    border-left: 6px solid #AEC7A6;
}

.sliderColor2 {
    border-left: 6px solid #5A8A8A;
}

.sliderColor3 {
    border-left: 6px solid #DEC591;
}

.sliderColor4 {
    border-left: 6px solid #E9B9AA;
}

.sliderColor5 {
    border-left: 6px solid #8CB9C0;
}

.sliderColor6 {
    border-left: 6px solid #7892B5;
}

.silder--title {
    margin-bottom: 0 !important;
    font-size: 13px
}

.silder--data {
    margin-bottom: 0 !important;
    font-size: 30px;
    font-weight: 700;
    margin-top: -7px;
}

.silder--unit {
    margin-bottom: 0 !important;
    /* margin-left: 65px !important; */
    font-size: 14px;
    color: #001323;
    letter-spacing: 0.42px;
    margin-top: -8px;
    /* text-align: end; */
    /* margin-right: 20px; */
    margin-right: 36px;
}

.slider-data-div {
    margin-top: 38px;
}

@media (min-width: 767px) and (max-width: 960px) {
    .process-card {
        display: block !important;
        gap: 15px;
    }
    .process-grid {
        margin-top: 9px !important;
    }
    .process-card-chart {
        display: block !important;
        gap: 15px;
    }
    .process-header-btn-date {
        width: 35%;
        margin-right: 10px;
    }
    .process-header-btn-month {
        width: 35%;
    }
    .graph-box {
        flex-direction: column;
    }
    .Analytics-deviceModal-popup {
        left: 22% !important;
    }
    .slider-data-div {
        margin-top: -12px;
    }
}

.doughnut-chart {
    width: 100%;
    height: 250px;
    position: relative;
}

.doughnut-chartText {
    font-size: 22px;
    font-weight: bold;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.doughnut-chartText-small {
    /* font-size: 10px; */
    position: absolute;
    top: 49%;
    left: 54%;
    transform: translate(-50%, -50%)7
}


/* .processdasboard-barChart {
    min-height: 300px;
} */

@media (min-width: 961px) and (max-width: 1024px) {
    .process-header-btn-date {
        width: 42%;
        margin-right: 10px;
    }
    .process-header-btn-month {
        width: 42%;
    }
    .graph-box {
        flex-direction: column;
    }
    .Analytics-deviceModal-popup {
        left: 28%;
    }
    .slider-data-div {
        margin-top: 17px;
    }
}


/* @media (min-width: 899.98px) and (max-width: 1024px){
  .process-card {
    display: block !important;
    gap: 15px;
  }
  .process-grid{
    margin-top: 9px !important;
  }
  .process-card-chart{
    display: block !important;
    gap: 15px;
  }
  .process-header-btn-date{
    width: 38%;
    margin-right: 10px;
  }
  .process-header-btn-month{
    width: 38%;
  }
  .graph-box{
    flex-direction: column;
    }
} */

@media (min-width: 1025px) and (max-width: 1300px) {
    .graph-box {
        flex-direction: column;
    }
    .process-header-btn-date {
        width: 38%;
        margin-right: 10px;
    }
    .process-header-btn-month {
        width: 38%;
    }
    .graph-box {
        flex-direction: column;
    }
    .Analytics-deviceModal-popup {
        left: 31% !important;
    }
    .slider-data-div {
        margin-top: 12px;
    }
}

@media (min-width: 1300px) and (max-width: 1536px) {
    .process-header-btn-date {
        width: 38%;
        margin-right: 10px;
    }
    .process-header-btn-month {
        width: 38%;
    }
    .graph-box {
        flex-direction: column;
    }
    .Analytics-deviceModal-popup {
        max-height: 500px;
        overflow: auto;
        left: 31% !important;
    }
    .submitCancleBtn {
        margin-bottom: 20px;
    }
    .slider-data-div {
        margin-top: 11px;
    }
}