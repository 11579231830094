.bridgeDevice-headerContent {
    padding: 10px 14px 0px 14px;
}

.bridgeDevice-detailsWrap {
    display: flex;
    justify-content: space-between;
}

.bridgeDevice-details {
    display: flex;
    flex-direction: column;
}

.bridgeDevice-uidDetailswrap {
    display: flex;
    flex-direction: column;
}

.bridgeDevice-uidDetails {
    display: flex;
    /* gap: 20px; */
    gap: 70px;
    /* justify-content: space-between !important; */
}

.bridgeDevice-tagDetails {
    display: flex;
    gap: 20px;
}


/* ================ */

.devicetagWrap {
    min-height: 700px;
    padding: 10px 0px 0px 10px;
}

.deviceWrap {
    /* height: 400px; */
    /* min-height: 525px; */
    /* overflow: auto !important; */
    /* width: auto; */
    /* height: 300px; */
    height: 650px;
    /* min-height: 525px; */
    overflow: auto !important;
    /* width: auto; */
}

.deviceTitleWrap {
    position: sticky;
    top: 0;
    background-color: #fff;
    /* padding: 10px 10px 10px 10px; */
    padding: 10px 0px 10px 0px;
    z-index: 99;
}

.deviceTagtitle {
    font-size: 16px !important;
    font-weight: 600 !important;
}

.devicetagBox {
    border: 1px solid #AEBCD2;
    border-radius: 4px;
    padding: 10px 0px 10px 10px;
    /* box-shadow: #6D6D6D1A; */
    /* width: 90%; */
    width: 98%;
    margin-top: 10px;
    /* box-shadow: 0px 0px 20px #6D6D6D1A; */
    box-shadow: 0px 0px 8px #6D6D6D1A;
    position: relative;
}

.dragIcon.bridge {
    position: absolute;
    right: 7px;
    top: 8px;
    color: #AEBCD2;
}

.devicetagBox-text {
    color: #001323;
    margin-bottom: 0px !important;
}

.hiCloudtagBox {
    border: 1px solid #AEBCD2;
    background-color: #F6F6F6;
    border-radius: 4px;
    height: 41px;
    padding: 10px 0px 10px 10px;
    /* box-shadow: #6D6D6D1A; */
    /* box-shadow: 0px 0px 20px #6D6D6D1A; */
    box-shadow: 0px 0px 8px #6D6D6D1A;
    width: 90%;
    margin-top: 10px;
}

.hiCloudtagBox-text {
    color: #001323;
    margin-bottom: 0px !important;
}

.hiCloudtagBox-drag {
    border: 1px dashed #AEBCD2;
    border-radius: 4px;
    height: 41px;
    padding: 10px 0px 10px 10px;
    /* box-shadow: #6D6D6D1A; */
    /* box-shadow: 0px 0px 20px #6D6D6D1A; */
    box-shadow: 0px 0px 8px #6D6D6D1A;
    width: 90%;
    margin-top: 10px;
}

.hiCloudWrap {
    /* height: 300px; */
    height: 650px;
    /* min-height: 525px; */
    overflow: auto !important;
    /* width: auto; */
}

.hiCloudTitleWrap {
    position: sticky;
    top: 0;
    background-color: #fff;
    /* padding: 10px 10px 10px 10px; */
    padding: 10px 0px 10px 0px;
    z-index: 99;
}

.hiCloudTitle {
    font-size: 16px !important;
    font-weight: 600 !important;
}
.hiCloud-text{
    font-size: 8px;
    border: 1px solid black;
    border-radius: 50%;
    padding: 0px 2px 0px 2px;
}

.hiCloudtagWrap {
    /* min-height: 700px; */
    min-height: 700px;
    /* padding: 10px 0px 0px 10px; */
    padding: 0px 0px 0px 10px;
    position: relative;
}
.configuration-submitBtnWrap{
    position: sticky;
    bottom: 0;
    background-color: #fff;
    /* padding-top: 10px; */
    z-index: 99;
    /* padding: 10px 0px 20px 0px; */
}

.Bridge.submit {
    background-color: #1C407B;
    color: #fff;
    border: 1px solid #1E3760;
    border-radius: 4px;
    padding: 8px 60px;
    margin-top: 20px;
    text-transform: capitalize;
    float: right;
    position: absolute;
    /* bottom: 14px; */
    bottom: -47px;
    /* bottom: -8px; */
    /* right: 14px; */
    right: 30px;
}

.Board {
    width: 300px;
    height: 500px;
    border: 2px solid black;
}