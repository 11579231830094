.subscriptionTitle-wrap {
    display: flex;
    align-items: center;
    padding-top: 20px;
}

.subscriptionTitle-wrap .backArrow {
    cursor: pointer;
}

.subscription-header {
    display: flex;
    align-items: center !important;
    margin-top: 20px;
    margin-bottom: 20px;
}

.subscriptionCard {
    position: relative;
    height: auto;
    width: 450px !important;
    /* width: auto !important; */
}

.subscriptionCard-Image {
    position: relative;
    width: 100%;
}

.imageWrap {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 50px;
    border-radius: 50%;
    padding: 60px;
    box-shadow: 0px 12px 20px #00000029;
}

.subscriptionCard-Icon {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: 30px;
}

.subscriptionContent {
    padding-top: 50px;
}

.subscription-title {
    text-align: center;
    color: #001323;
    font-weight: 600 !important;
    padding-top: 25px;
}

.subscription-text {
    text-align: center;
}

.subscription-details {
    color: #001323;
    font-weight: 400 !important;
}

.checkIconbg {
    background-color: transparent !important;
}

.checkIcon {
    background-color: #50C878;
    border-radius: 50%;
}

@media(min-width:1640px) and (max-width:1895px) {
    .subscriptionCard {
        width: auto !important;
    }
}

@media(min-width:1025px) and (max-width:1640px) {
    .subscriptionCard {
        width: auto !important;
    }
}

@media(min-width:899.98px) and (max-width:1024px) {
    .subscriptionCard {
        width: auto !important;
    }
}

@media (min-width: 767px) and (max-width: 899.98px) {
    .subscriptionCard {
        width: auto !important;
    }
}