.userManagementTable{
    padding-top: 20px;
}
/* .updateUserDropdow.form-select {
    border: 1px solid #000 !important;
    font-size: 12px !important;
} */
.updateUserDropdown{
    border: 1px solid #000;
    font-size: 14px;
    padding: 10px;
}
.updateUserDropdown option {
    font-size: 16px;
  }
.form-select-lg.updateUserDropdow{
    border: 1px solid #000 !important;
    font-size: 12px !important;
} 
.updateUserDropdown.form-select:focus{
box-shadow: none !important;
border: 1px solid #000;
}
.ClientManagement.InviteUser-Button.userManagement{
    padding: 6px 56px;
}

.disabledRow {
    pointer-events: none; /* Disable pointer events */
  
  }
