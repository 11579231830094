.reportManagement-schedule-dropdown{
    border: 1px solid #000;
    font-size: 14px;
    padding: 10px;
    border-radius: 4px;
}
.reportManagement-schedule-card {
    background-color: #F1F1FF;
    min-height: 620px;
    margin-right: 10px;
    padding: 12px 20px 0px 20px;
}
.reportManagement-deviceLists-btn{
    border: 0;
    border-radius: 3px;
    font-size: 12px;
    padding: 6px 20px 6px 20px;
    background-color: #1C407B;
    color: #fff;
}
.reportManagement-deviceLists-btn1{
    border: 0;
    border-radius: 3px;
    font-size: 12px;
    padding: 6px 20px 6px 20px;
    background-color:#1c407bad;
    color: #fff;
}
.reportMangement-titleWrap{
    display: flex;
    justify-content: space-between;
}
.reportManagement-taglistModal-title{
    color: #fff;
    font-size: 20px !important;
    margin-bottom: 0px !important;
    margin-left: 10px !important;
}
.fff{
    min-height: 400px;
}
.card-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    overflow: hidden; /* Add this line to hide overflowing content */
  }
  
  .reportManagement-tagListModal {
    max-height: 80vh;
    overflow: auto;
    z-index: 99;
    background-color: #fff;
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .table-container {
    height: calc(80vh - 48px); /* Subtract the height of the header */
    overflow: auto;
  }
  
  .sticky-header th {
    position: sticky;
    top: 0;
    background-color: red;
    z-index: 99;
  }
  
  .scrollable-body {
    overflow-y: scroll;
    color: #1C407B;
  }
  
  .closeImg {
    width: 30px;
    cursor: pointer;
    background-color: white;
  }
  
  
  
